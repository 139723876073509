/** @format */

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css"; // External CSS for styling the footer

function Footer() {
	return (
		<footer className="footer bg-dark text-light py-5">
			<Container>
				<Row>
					<Col
						md={6}
						className="d-flex flex-column justify-content-center contact-us">
						<h5 className="footer-heading">Contact Us</h5>
						<p>
							<strong>Address:</strong> Nethaji Road, Kamareddy, Telangana, ZIP
							503111
						</p>
						<p>
							<strong>Phone:</strong>{" "}
							<a href="tel:+918688044440" className="footer-link">
								+91 8688044440
							</a>
						</p>
					</Col>
					<Col md={6}>
						<h5 className="footer-heading">Our Location</h5>
						<div className="map-container">
							<iframe
								title="Google Maps Location"
								src="https://maps.google.com/maps?width=1353&amp;height=260&amp;hl=en&amp;q=Anjhal%20Reddy%20Hospital%20Kamareddy+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
								width="100%"
								height="250"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"></iframe>
						</div>
					</Col>
				</Row>
				<hr className="footer-divider" />
				<Row className="text-center mt-3">
					<Col>
						<p className="footer-copy">
							&copy; {new Date().getFullYear()}{" "}
							<a href="https://hextgen.com/" className="footer-link">
								HextGen
							</a>
							. All Rights Reserved.
						</p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default Footer;
