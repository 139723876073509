/** @format */

import React from "react";

const AboutUs = () => {
	return (
		<div id="about">
			<div className="d-none d-lg-block">
				<div className="about-us">
					<div className="about-us-content">
						<h2>About Us</h2>
						<p>
							Established over half a century ago, Anjal Reddy Memorial Hospital
							(AMH) has become a pillar of healthcare excellence in the
							community. It was founded and meticulously managed by the
							distinguished Dr. Karangula Anjal Reddy. His dedication to the
							medical profession and compassionate approach to patient care laid
							a strong and enduring foundation, earning the hospital a stellar
							reputation and deep trust from those it served. Dr. Anjal Reddy
							was known not only for his exceptional medical skills but also for
							his visionary leadership, which helped the hospital grow and adapt
							to the changing needs of the healthcare landscape over the
							decades. Today, the rich legacy of Anjal Reddy Memorial Hospital
							is carried forward by his son, Karangula Dinesh Reddy, and his
							daughter-in-law, Dr. Karangula Saritha Reddy. The couple has
							seamlessly taken on the mantle of leadership, ensuring that the
							hospital continues to thrive and evolve.
						</p>
					</div>
					<div>
						<img
							src="photographofanjalreddy-removebg.png"
							alt="Dr. Karangula Anjal Reddy"
						/>
					</div>
				</div>
			</div>
			<div className="d-block d-lg-none">
				<div>
					<div className="col-12 text-center">
						<h2>About Us</h2>
						<div>
							<img
								src="photographofanjalreddy-removebg.png"
								alt="Dr. Karangula Anjal Reddy"
								height={275}
								width={275}
							/>
						</div>
						<p>
							Established over half a century ago, Anjal Reddy Memorial Hospital
							(AMH) has become a pillar of healthcare excellence in the
							community. It was founded and meticulously managed by the
							distinguished Dr. Karangula Anjal Reddy. His dedication to the
							medical profession and compassionate approach to patient care laid
							a strong and enduring foundation, earning the hospital a stellar
							reputation and deep trust from those it served. Dr. Anjal Reddy
							was known not only for his exceptional medical skills but also for
							his visionary leadership, which helped the hospital grow and adapt
							to the changing needs of the healthcare landscape over the
							decades. Today, the rich legacy of Anjal Reddy Memorial Hospital
							is carried forward by his son, Karangula Dinesh Reddy, and his
							daughter-in-law, Dr. Karangula Saritha Reddy. The couple has
							seamlessly taken on the mantle of leadership, ensuring that the
							hospital continues to thrive and evolve.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
