/** @format */

import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./Header.css"; // External CSS file for better UI customization

function Header() {
	return (
		<Navbar expand="lg" bg="dark" variant="dark" className="custom-navbar">
			<Container>
				<Navbar.Brand href="#home">
					<img
						src="/logo3.png"
						height={70}
						width={80}
						alt="Hospital Logo"
						className="logo"
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ml-auto nav-links">
						<Nav.Link href="#about" className="nav-item">
							About Us
						</Nav.Link>
						<Nav.Link href="#doctors" className="nav-item">
							Doctors
						</Nav.Link>
						<Nav.Link href="#contact" className="nav-item">
							Contact Us
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Header;
