/** @format */

import React from "react";
import Header from "./Header";
import HeroSection from "./Hero";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Doctors from "./Doctors";
import "./styles.css";
import Footer from "./Footer";

function MainComponent() {
	return (
		<div className="App">
			<Header />
			<HeroSection />
			<AboutUs />
			{/* <Services /> */}
			<Doctors />
			<Footer />
		</div>
	);
}

export default MainComponent;
